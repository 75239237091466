import { BriefcaseIcon } from "@heroicons/react/24/outline";
import useServer from "../api/useServer";
import { useEffect } from "react";
import StatusCard from "../components/misc/StatusCardWithIcon";
import Loading from "../components/misc/Loading";


const Dashboard = () => {
  //const { getSumaryData, sumaryData, isLoading } = useServer();

  //useEffect(() => {
  //  getSumaryData();
  //}, []);

  //if (isLoading) {
  //  return <Loading />;
  //} else {
    return (
      <main className="flex-1">
        
      </main>
    );
  };

export default Dashboard;
