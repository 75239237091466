import { Route, Routes } from "react-router-dom";

import NotFoundpage from "../pages/NotFoundPage";
import Dashboard from "../pages/DashboardPage";
import "react-toastify/dist/ReactToastify.css";

import AppContainer from "../containers/AppContainer";

import Tickets from "../pages/Tickets";
import Contactos from "../pages/Contacts";
import Devices from "../pages/Devices";

const AppRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<AppContainer />}>
        <Route index element={<Dashboard />} />
        <Route path="/tickets" element={<Tickets />} />
        <Route path="/Contacts" element={<Contactos />} />
        <Route path="/Devices" element={<Devices />} />
      </Route>
      <Route path="/*" element={<NotFoundpage />} />
    </Routes>
  );
};

export default AppRoute;
