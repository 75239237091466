import { useState } from "react";
import Input from "../../../components/forms/Input";
import Button from "../../../components/misc/Button";
import {
  deleteUndefinedAttr,
  validateEmail,
} from "../../../utils/helpers";
import Toggle from "../../../components/forms/Toggle";
import { SubmitHandler, useForm } from "react-hook-form";
import useServerUser from "../../../api/userServerUser";
import { TicketsInterface, UserInterface } from "../../../interfaces/ServerInterfaces";
import { TrashIcon } from "@heroicons/react/24/outline";
import Modal from "../../../components/modals/GenericModal";
import AlertContainer from "../../../components/misc/AlertContainer";
import { BasicType, SelectInterface } from "../../../interfaces/InterfacesLocal";
import TextArea from "../../../components/forms/TextArea";
import Select from "../../../components/forms/Select";
import AsyncComboBox from "../../../components/forms/AsyncCombobox";
import ComboBox from "../../../components/forms/Combobox";

interface EditInterface {
  user: TicketsInterface | null;
  editUser: Function;
  deleteUser: Function;
  closeModal: Function;
  isFetching: boolean;
}
const selectData = [{ id: 1, name: 'abierto' }, { id: 2, name: 'cerrado' }]

const DetailUserEditComponent = ({
  editUser,
  deleteUser,
  user,
  closeModal,
  isFetching,
}: EditInterface) => {
  const { control, handleSubmit, watch, reset, formState } = useForm<BasicType>(
    {
      mode: "onChange",

    }
  );
  const { isFetching: loadingPsw } = useServerUser();
  const { isFetching: fetchingUser } = useServerUser();
  const { isFetching: fetchingMail } = useServerUser();
  const [delAction, setDelAction] = useState(false);

  const prioridad: SelectInterface[] = [
    { id: "1", name: "baja" },
    { id: "2", name: "media" },
    { id: "3", name: "alta" },
  ];

  const clasificacion: SelectInterface[] = [
    { id: "1", name: "Conectividad" },
    { id: "2", name: "Plataforma Web" },
    { id: "3", name: "Aplicaciones Móviles" },
    { id: "4", name: "Servidores" },
  ];

  const onSubmit: SubmitHandler<BasicType> = (data) => {
    editUser(user?.id, deleteUndefinedAttr(data), reset());
  };

  const email = watch("email") ?? user?.email ?? null;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="h-96 overflow-auto scrollbar-thin scrollbar-thumb-slate-100 pr-5 pl-2">
          <div className="flex justify-end">
            <div className="bg-red-200 hover:bg-red-300 transition-all duration-200 ease-in-out  rounded-lg"><Button
              icon={<TrashIcon className="h-5 text-gray-700" />}
              color="gray-500"
              action={() => setDelAction(true)}
              outline
            /></div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <AsyncComboBox
              name="name"
              defaultItem={user ? { id: user?.id, name: user?.name.toString() } : undefined}
              control={control}
              rules={{ required: "Campo requerido" }}
              label="Cliente"
              dataQuery={{ url: "/contact" }}
              normalizeData={{ id: 'id', name: "name" }}
            ></AsyncComboBox>

            <AsyncComboBox
              name="contact_id"
              defaultItem={user ? { id: user?.id, name: user.name} : undefined}
              control={control}
              rules={{ required: "Campo requerido" }}
              label="Contacto"
              dataQuery={{ url: "/contact" }}
              normalizeData={{ id: 'id', name: "name" }}
            ></AsyncComboBox>
            <ComboBox
              name="classification"
              displayValue={user?.classification}
              Conectividad={true}
              control={control}
              label="Clasificacion"
              data={clasificacion}
            ></ComboBox>


            <ComboBox
              name="classification"
              displayValue={user?.priority}
              Conectividad={true}
              control={control}
              label="Clasificacion"
              data={prioridad}
            ></ComboBox>
          </div>
          <Select
            name="status"
            label="Estado"
            data={selectData}
            control={control}></Select>
          <TextArea

            defaultValue={user?.description}
            name="description"
            control={control}
            label="descripcion"
          ></TextArea>

          <div className="flex justify-end mt-5">
            <Button
              name="Actualizar"
              color="slate-600"
              type="submit"
              loading={isFetching}
              disabled={isFetching || loadingPsw}
            />
          </div>
        </div>
      </form>

      {delAction && (
        <Modal state={delAction} close={setDelAction}>
          <AlertContainer
            onAction={() => deleteUser(user?.id, closeModal)}
            onCancel={setDelAction}
            title={`Eliminar ${user?.name}`}
            text="¿Seguro que desea eliminar este usuario del sistema?"
            loading={isFetching}
          />
        </Modal>
      )}
    </>
  );
};

export default DetailUserEditComponent;
