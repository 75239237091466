import { PlusIcon, TicketIcon, UsersIcon, UserGroupIcon } from "@heroicons/react/24/outline";
//import useServerClients from "../../api/useServerClients";
import GenericTable, {
    DataTableInterface,
    FilterOpts,
} from "../components/misc/GenericTable";
import useServerUser from "../api/userServerUser";

import Paginate from "../components/misc/Paginate";
import Modal from "../components/modals/GenericModal";
import Breadcrumb, { PathInterface } from "../components/navigation/Breadcrumb";
//import AddClientWizzard from "./addClient/AddClientWizzard";
import { useNavigate } from "react-router-dom";
//import { BsFiletypeXlsx } from "react-icons/bs";
//import { SubmitHandler, useForm } from "react-hook-form";
//import Input from "../../components/forms/Input";
//import Button from "../../components/misc/Button";
import { BasicType, SelectInterface } from "../interfaces/InterfacesLocal";
//import useServerOnlineClients from "../../api/useServerOnlineClients";
//import { formatCalendar } from "../../utils/helpers";
//import { translateRegWay } from "../../utils/translate";
import { useEffect, useState } from "react";
import NuevoTicketModal from "../components/modals/NuevoTicketModal";
import { data } from "../utils/TemporaryArrayData"
import { useAppDispatch } from "../store/hooks";
import { saveContactoSelection } from "../store/slices/ticketSlice";


import EditUserContainer from "../containers/tickets/editTicketWizzard/EditUserContainer";
import CreateContactModal from "../components/modals/CreateContactModal";
import EditContactContainer from "../containers/tickets/editTicketWizzard/EditContactContainer";
import { formatCalendar } from "../utils/helpers";

const Contactos = () => {
    const dispatch = useAppDispatch()
    const [query, setQuery] = useState<string>("");
    const [queryText, setQueryText] = useState("");
    const [post, setPost] = useState(null)

    const close = () => setEditTicketModal({ state: false, id: null })
    const closeAdd = () => setAddTicketmodal(false)

    const closeContactModal = () => {
        setContactModal(false);
        dispatch(saveContactoSelection({ id: "", name: "" }));
    };

    const {

        allContacts,
        paginate,

        editUser,
        getContact,
        addContact,
        editContact,
        deleteContact,
        isLoading,
        isFetching,
        getAllContacts,
        user,
    } = useServerUser();

    const handleSearch = (e: any) => {
        e.preventDefault();
        setQuery(queryText);
    };

    const filteredOptions =
        query === ""
            ? data
            : data.filter(
                ({
                    cliente,
                    no,
                    fecha,
                    description,
                    prioridad,
                    clasificacion,
                    email,
                }) => {
                    return (
                        cliente.toLowerCase().includes(query.toLowerCase()) ||
                        no.toLowerCase().includes(query.toLowerCase()) ||
                        prioridad.toLowerCase().includes(query.toLowerCase()) ||
                        clasificacion.toLowerCase().includes(query.toLowerCase()) ||
                        email.toLowerCase().includes(query.toLowerCase()) ||
                        fecha.toLowerCase().includes(query.toLowerCase()) ||
                        cliente.toLowerCase().includes(query.toLowerCase())
                    );
                }
            );

    /*const {
                getAllClients,
                addClient,
                allClients,
                paginate,
                isLoading,
                isFetching,
              } = useServerOnlineClients();*/

    const [filter, setFilter] = useState<
        Record<string, string | number | boolean | null>
    >({});
    const [addTicketmodal, setAddTicketmodal] = useState(false);
    //const [exportModal, setExportModal] = useState(false);

    /*useEffect(() => {
                getAllClients(filter);
              }, [filter]);*/

    //Data for table ------------------------------------------------------------------------
    const tableTitles = [
        "No.",
        "Nombre",
        "Telefono",
        "Rol",
        "Fecha",



        "Descripcion",
    ];
    const tableData: DataTableInterface[] = [];
    // eslint-disable-next-line array-callback-return


    allContacts?.map((item) => {
        tableData.push({
            rowId: item.id,
            payload: {
                "No.": item.id,
                Nombre: `${item?.name}`,
                Telefono: item?.phone,
                Rol: item?.role,
                Descripcion: item.description,
                Fecha: formatCalendar(item.createdAt)
            },
        });
    });

    const searching = {
        action: (search: string) => setFilter({ ...filter, search }),
        placeholder: "Buscar ticket",
    };

    const actions = [
        {
            icon: <PlusIcon className="h-5" />,
            title: "Nuevo Contacto",
            action: () => setAddTicketmodal(true),
        },
        /*{
                    title: "Exportar a excel",
                    action: () => setExportModal(true),
                    icon: <BsFiletypeXlsx />,
                  },*/
    ];

    const rowAction = (id: number) => {
        setEditTicketModal({ state: true, id });
    };

    //Filters-----------------------------------
    const registrationSelector: SelectInterface[] = [
        {
            id: "WOO",
            name: "WOO",
        },
        {
            id: "ONLINE",
            name: "ONLINE",
        },
        {
            id: "POS",
            name: "POS",
        },
    ];

    const sexSelector: SelectInterface[] = [
        {
            id: "female",
            name: "Femenino",
        },
        {
            id: "male",
            name: "Masculino",
        },
    ];

    const availableFilters: FilterOpts[] = [
        //País
        {
            format: "select",
            filterCode: "countryId",
            name: "País",
            asyncData: {
                url: "/public/countries",
                idCode: "id",
                dataCode: "name",
            },
        },
        //Provincia
        {
            format: "select",
            filterCode: "provinceId",
            name: "Provincia",
            dependentOn: "countryId",
            asyncData: {
                url: "/public/provinces",
                idCode: "id",
                dataCode: "name",
            },
        },
        //Municipio
        {
            format: "select",
            filterCode: "municipalityId",
            name: "Municipio",
            dependentOn: "provinceId",
            asyncData: {
                url: "/public/municipalities",
                idCode: "id",
                dataCode: "name",
            },
        },
        //Forma de registro
        {
            format: "select",
            filterCode: "registrationWay",
            name: "Forma de registro",
            data: registrationSelector,
        },
        //Nacimiento desde
        {
            format: "datepicker",
            filterCode: "birthFrom",
            name: "Fecha de nacimiento desde",
        },
        //Nacimiento hasta
        {
            format: "datepicker",
            filterCode: "birthTo",
            name: "Fecha de nacimiento hasta",
        },
        //Forma de registro
        {
            format: "select",
            filterCode: "sex",
            name: "Sexo",
            data: sexSelector,
        },
    ];

    const filterAction = (data: BasicType) => setFilter(data);
    //----------------------------------------------------------------------------------

    //Breadcrumb-----------------------------------------------------------------------------------
    const paths: PathInterface[] = [
        {
            name: "Contacts",
        },
    ];
    //------------------------------------------------------------------------------------
    const [nuevoTicketModal, setNuevoTicketModal] = useState(false);
    const [contactModal, setContactModal] = useState(false);
    const [editTicketModal, setEditTicketModal] = useState<{
        state: boolean;
        id: number | null;
    }>({ state: false, id: null });
    const navigate = useNavigate();


    useEffect(() => {

        getAllContacts(filter).then();

    }, [filter]);
    return (
        <div>
            <Breadcrumb
                icon={<UserGroupIcon className="h-6 text-gray-500" />}
                paths={paths}
            />
            <GenericTable
                tableData={tableData}
                tableTitles={tableTitles}
                loading={isLoading}
                searching={searching}
                actions={actions}
                rowAction={rowAction}
                //filterComponent={{ availableFilters, filterAction }}
                paginateComponent={
                    <Paginate
                        action={(page: number) => setFilter({ ...filter, page })}
                        data={paginate}
                    />
                }
            />

            {addTicketmodal && (
                <Modal state={addTicketmodal} close={closeAdd}>
                    <CreateContactModal close={closeAdd}></CreateContactModal>
                </Modal>
            )}
            {editTicketModal.state && (
                <Modal
                    state={editTicketModal.state}
                    close={close}
                    size="m"
                >
                    <EditContactContainer
                        id={editTicketModal.id}
                        editContact={editContact}
                        deleteContact={deleteContact}
                        isFetching={isFetching}
                        closeModal={close}
                    />
                </Modal>)}

            {/*exportModal && (
      //            <Modal state={exportModal} close={setExportModal}>
      //              <ExcelFileExport
      //                filter={filter}
      //                closeModal={() => setExportModal(false)}
      //              />
      //            </Modal>
      //          */}

        </div>
    );

    /*interface ExportContainer {
              filter: BasicType;
              closeModal: Function;
            }*/

    /*const ExcelFileExport = ({ filter, closeModal }: ExportContainer) => {
              const { handleSubmit, control } = useForm();
              const { exportClients, isLoading } = useServerClients();
            
              const onSubmit: SubmitHandler<Record<string, string>> = (data) => {
                exportClients(filter, data.name, closeModal());
              };
            
              return (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    name="name"
                    label="Nombre del archivo"
                    placeholder="Nombre del archivo .xlsx"
                    control={control}
                    rules={{ required: "Debe indicar un nombre para el archivo" }}
                  />
                  <div className="flex py-2 justify-end">
                    <Button
                      type="submit"
                      name="Exportar"
                      color="slate-600"
                      loading={isLoading}
                      disabled={isLoading}
                    />
                  </div>
                </form>
              );*/
};

export default Contactos;
