import { useState, createContext, useEffect } from "react";
import DetailContactEditComponent from "./DetailContactEditComponent";
import Fetching from "../../../components/misc/Fetching";
import TabNav from "../../../components/navigation/TabNav";
import useServerUser from "../../../api/userServerUser";


interface UserWizzardInterface {
  id: number | null;
  editContact: Function;
  deleteContact: Function;
  isFetching: boolean;
  closeModal: Function;
}

const EditContactContainer = ({
  id,
  editContact,
  deleteContact,
  isFetching,
  closeModal,
}: UserWizzardInterface) => {
  const { getContact, contact, isLoading } = useServerUser();

  useEffect(() => {
    id && getContact(id).then( () =>console.log(contact))
  }, []);

  //Tabs data --------------------------------------------------------------------------------------------
  const [currentTab, setCurrentTab] = useState("details");
  const tabs = [
    { name: "Detalles", href: "details", current: currentTab === "details" },
  ];

  const action = (href: string) => setCurrentTab(href);

  //------------------------------------------------------------------------------------------------------

  if (isLoading)
    return (
      <div className="h-96">
        <Fetching />
      </div>
    );
  return (
    <div>
      {isFetching && <Fetching />}
      <TabNav tabs={tabs} action={action} />
      {currentTab === "details" && (
        <DetailContactEditComponent
          editUser={editContact}
          deleteUser={deleteContact}
          user={contact}
          closeModal={closeModal}
          isFetching={isFetching}
        />
      )}
    </div>
  );
};

export default EditContactContainer;
