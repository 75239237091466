import Input from "../forms/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "../misc/Button";
import useServerUser from "../../api/userServerUser";
import { deleteUndefinedAttr } from "../../utils/helpers";

interface propsDestructured {
  close: Function
}



const CreateContactModal = ({ close, }: propsDestructured

) => {
  const { addContact } = useServerUser()
  const { control, handleSubmit } = useForm();

  const onSubmit: SubmitHandler<Record<string, string | number | boolean | string[]>> = (
    data
  ) => {
    const date = Object.assign(data, {
      externalCostumerId: 12345,
      isPrincipal: true,
      customer_id: 6789
    })
    console.log(date)
    addContact(deleteUndefinedAttr(date), close)
  };

  return (
    <main>
      <form className="relative" action="submit"></form>
      <h3 className="p-4 text-xl md:text-2xl">Nuevo Contacto</h3>
      <form
        className="flex flex-col gap-y-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          name="name"
          control={control}
          rules={{ required: "Campo requerido" }}
          label="Cliente"
          type={"text"}
        />
        <Input
          name="role"
          control={control}
          rules={{ required: "Campo requerido" }}
          label="Cargo"
          type={"text"}
        />
        <Input
          name="phone"
          control={control}
          rules={{ required: "Campo requerido" }}
          label="Telefono"
          type={"text"}
        />
        <div className="relative rounded-lg self-center lg:self-end w-[100%] lg:w-[30%] h-[40px] items-center justify-center flex mt-8 bg-indigo-600  text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
          <button type="submit" className=" w-full h-full">
            Crear
          </button>
        </div>
      </form>
    </main>
  );
};

export default CreateContactModal;
