import { useState } from "react";
import {
  PaginateInterface,
  TicketData,
  TicketsInterface,
  ContactsInterface,
  UserInterface,
  DeviceInterface
} from "../interfaces/ServerInterfaces";
import query from "./APIServices";
import useServer from "./useServer";
import { Flip, toast } from "react-toastify";
import { updateUserState } from "../store/slices/initSlice";
import { saveItems } from "../store/slices/ticketSlice";
import { useAppDispatch } from "../store/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { generateUrlParams } from "../utils/helpers";
import { BasicType } from "../interfaces/LocalInterfaces";

const useServerUser = () => {
  const { manageErrors } = useServer();
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [paginate, setPaginate] = useState<PaginateInterface | null>(null);
  const [allUsers, setAllUsers] = useState<Array<TicketsInterface>>([]);
  const [allTickets, setAllTickets] = useState<Array<TicketsInterface>>();
  const [allContacts, setAllContacts] = useState<Array<ContactsInterface>>([]);
  const [allDevices, setAllDevices] = useState<Array<DeviceInterface>>([]);
  const [user, setUser] = useState<TicketsInterface | null>(null);
  const [contact, setContact] = useState<ContactsInterface | null >(null);
  const [device, setDevice] = useState<TicketsInterface | null>(null);
  const [modalWaiting, setModalWaiting] = useState<boolean>(false);
  const [render, setRender] = useState<boolean>(false)
  const [modalWaitingError, setModalWaitingError] = useState<string | null>(
    null
  );
  const [waiting, setWaiting] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  /*const addUser = async (data: BasicType, callback: Function) => {
    setIsFetching(true);
    await query
      .post("/tickets", data)
      .then(async (resp) => {
        setAllUsers([resp.data, ...allUsers]);
        callback && callback();
      })
      .catch((error) => manageErrors(error));
    setIsFetching(false);
  };*/
  const addUser = async (
    data: Record<string, string | number | boolean>,
    close: Function
  ) => {
    setIsFetching(true);
    await query
      .post("/tickets", data)
      .then((resp) => {
        //setAllTickets([resp.data, allTickets]);
        close();
        toast.success("Ticket agregado satisfactoriamente");
      }).then(()=>setTimeout(()=>getAllUsers({}), 1000) )
      .catch((e) => manageErrors(e));
    setIsFetching(false);
  };

  const getAllUsers = async (filter: BasicType) => {
    setIsLoading(true);
    await query
      .get(`/tickets${generateUrlParams(filter)}`)
      .then((resp) => {
        const { currentPage, totalItems, totalPages, items } = resp.data;
        setPaginate({ totalItems, totalPages, currentPage });
        setAllTickets(items);
        console.log(items)
        console.log(allContacts)
      })
      .catch((error) => manageErrors(error));
    setIsLoading(false);
  };
  const getAllContacts = async (filter: BasicType) => {
    setIsLoading(true);
    await query
      .get(`/contact${generateUrlParams(filter)}`)
      .then((resp) => {
        const { currentPage, totalItems, totalPages, items } = resp.data;
        setPaginate({ totalItems, totalPages, currentPage });
        setAllContacts(items);
        console.log(items)
        console.log(allContacts)
      })
      .catch((error) => manageErrors(error));
    setIsLoading(false);
  };

  const getAllDevices = async (filter: BasicType) => {
    setIsLoading(true);
    await query
      .get(`/device${generateUrlParams(filter)}`)
      .then((resp) => {
        const { currentPage, totalItems, totalPages, items } = resp.data;
        setPaginate({ totalItems, totalPages, currentPage });
        setAllDevices(items);
        console.log(items)
        console.log(allContacts)
      })
      .catch((error) => manageErrors(error));
    setIsLoading(false);
  };


  const editContact = async (
    id: number,
    data: Record<string, string | number | boolean | string[]>,
    callback?: Function
  ) => {
    setIsFetching(true);
    await query
      .patch(`/contact/${id}`, data)
      .then((resp) => {
        const newUsers = [...allContacts];
        const idx = newUsers.findIndex((contact) => contact.id === id);
        newUsers.splice(idx, 1, resp.data);
        setAllContacts(newUsers);
        console.log(newUsers, resp.data);
        toast.success("Cambios realizados con éxito");
        callback && callback();
      }).then(()=>getAllContacts({}))
      .catch((e) => manageErrors(e));
    setIsFetching(false);
  };

  const getContact = async (id: any) => {
    setIsLoading(true);
    await query
      .get(`/contact/${id}`)
      .then((resp) => {
        setContact(resp.data);
        console.log(resp.data)
      })
      .catch((error) => manageErrors(error));
    setIsLoading(false);
  };



  const editDevice = async (
    id: number,
    data: Record<string, string | number | boolean | string[]>,
    callback?: Function
  ) => {
    setIsFetching(true);
    await query
      .patch(`/contact/${id}`, data)
      .then((resp) => {
        const newUsers = [...allContacts];
        const idx = newUsers.findIndex((contact) => contact.id === id);
        newUsers.splice(idx, 1, resp.data);
        setAllContacts(newUsers);
        console.log(newUsers, resp.data);
        toast.success("Cambios realizados con éxito");
        callback && callback();
      }).then(()=>getAllContacts({}))
      .catch((e) => manageErrors(e));
    setIsFetching(false);
  };

  const getDevice = async (id: any) => {
    setIsLoading(true);
    await query
      .get(`/contact/${id}`)
      .then((resp) => {
        setContact(resp.data);
        console.log(resp.data)
      })
      .catch((error) => manageErrors(error));
    setIsLoading(false);
  };

  


  const editUser = async (
    id: number,
    data: Record<string, string | number | boolean | string[]>,
    callback?: Function
  ) => {
    setIsFetching(true);
    await query
      .patch(`/tickets/${id}`, data)
      .then((resp) => {
        const newUsers = [...allUsers];
        const idx = newUsers.findIndex((user) => user.id === id);
        newUsers.splice(idx, 1, resp.data);
        setAllUsers(newUsers);
        console.log(newUsers, resp.data);
        toast.success("Cambios realizados con éxito");
        callback && callback();
      }).then(()=>getAllUsers({}))
      .catch((e) => manageErrors(e));
    setIsFetching(false);
  };

  const getUser = async (id: any) => {
    setIsLoading(true);
    await query
      .get(`/tickets/${id}`)
      .then((resp) => {
        setUser(resp.data);
        console.log(resp.data)
      })
      .catch((error) => manageErrors(error));
    setIsLoading(false);
  };

  const updateUser = async (
    userId: number,
    data: BasicType,
    callback?: Function
  ) => {
    setIsFetching(true);
    await query
      .patch(`/control/user/${userId}`, data)
      .then(async (resp) => {
        setUser(resp.data);
        const newUsers = [...allUsers];
        const idx = newUsers.findIndex((user) => user.id === userId);
        newUsers.splice(idx, 1, resp.data);
        setAllUsers(newUsers);
        callback && callback();
        toast.success("Actualización exitosa");
      })
      .catch((error) => manageErrors(error));
    setIsFetching(false);
  };

  const addContact = async (
    data: Record<string, string | number | boolean>,
    close: Function
  ) => {
    setIsFetching(true);
    await query
      .post("/contact", data)
      .then((resp) => {
        setAllContacts([resp.data, allContacts]);
        close();
        toast.success("Contacto agregado satisfactoriamente");
        window.location.reload()
      })
      .catch((e) => manageErrors(e));
    setIsFetching(false);
  };
  const deleteContact = async (id: number, callback?: Function) => {
    setIsFetching(true);
    await query
      .deleteAPI(`/contact/${id}`, {})
      .then(() => {
        toast.success("Usuario Eliminado con éxito");
        const newContacts = allContacts.filter((user) => user.id !== id);
        setAllContacts(newContacts);
        callback && callback();
      }).then(()=>getAllContacts({}))
      .catch((error) => manageErrors(error));
    setIsFetching(false);
  };

  const updateMyUser = (
    data: Partial<UserInterface>,
    closeModal?: Function
  ) => {
    setModalWaiting(true);
    const userID = data.id;
    delete data.id;
    query
      .patch(`/control/user/${userID}`, data)
      .then(async (resp) => {
        dispatch(updateUserState(resp.data));
        setWaiting(false);
        toast.success("Actualización exitosa", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
      .catch((error) => {
        let errorMsg = "";
        if (error.response?.data?.message) {
          errorMsg = error.response?.data?.message;
        } else {
          errorMsg = "Ha ocurrido un error. Contacte al administrador";
        }
        toast.error(errorMsg, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Flip,
        });
        setIsLoading(false);
      });
  };

  const resetUserPsw = async (email: string, callback?: Function) => {
    setIsFetching(true);
    await query
      .post(`/control/user/request-password`, { email })
      .then(() => {
        toast.success("Operación completada con éxito");
        callback && callback();
      })
      .catch((error) => manageErrors(error));
    setIsFetching(false);
  };

  const deleteUser = async (id: number, callback?: Function) => {
    setIsFetching(true);
    await query
      .deleteAPI(`/tickets/${id}`, {})
      .then(() => {
        toast.success("Usuario Eliminado con éxito");
        const newUsers = allUsers.filter((user) => user.id !== id);
        setAllUsers(newUsers);
        callback && callback();
      }).then(()=>getAllUsers({}))
      .catch((error) => manageErrors(error));
    setIsFetching(false);
  };
  return {
    paginate,
    isLoading,
    isFetching,
    waiting,
    modalWaiting,
    allUsers,
    user,
    setAllTickets,
    allTickets,
    getAllUsers,
    getAllContacts,
    editContact,
    getContact,
    deleteContact,
    getUser,
    editUser,
    addUser,
    updateUser,
    updateMyUser,
    deleteUser,
    resetUserPsw,
    manageErrors,
    modalWaitingError,
    addContact,
    allContacts,
    contact,
    allDevices,
    device,
    getAllDevices
    
  };
};
export default useServerUser;

