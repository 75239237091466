import CreateContactModal from "./CreateContactModal";
import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { SubmitHandler, useForm } from "react-hook-form";
import useServerUsers from "../../api/userServerUser";
import ComboBox from "../forms/Combobox";
import TicketAsyncComboBox from "../forms/TicketAsyncCombobox";
import ContactAsyncCombobox from "../forms/ContactAsyncCombobox";
import DeviceAsyncCombobox from "../forms/DeviceAsyncCombobox";
import TextArea from "../forms/TextArea";
import { SelectInterface } from "../../interfaces/LocalInterfaces";
import { useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import Modal from "../misc/GenericModal";
import { saveContactoSelection } from "../../store/slices/ticketSlice";
import { useAppDispatch } from "../../store/hooks";
import { deleteUndefinedAttr } from "../../utils/helpers";
import Button from "../misc/Button";
import AsyncComboBox from "../forms/AsyncCombobox";


interface propsDestructured {
  setContactModal: (contactModal: boolean) => void;
  contactModal: boolean;
  setNuevoTicketModal: (contactModal: boolean) => void;
  nuevoTicketModal: boolean;
  isFetching?: boolean,
  close: Function
}



const prioridad: SelectInterface[] = [
  { id: "1", name: "baja" },
  { id: "2", name: "media" },
  { id: "3", name: "alta" },
];

const clasificacion: SelectInterface[] = [
  { id: "1", name: "Conectividad" },
  { id: "2", name: "Plataforma Web" },
  { id: "3", name: "Aplicaciones Móviles" },
  { id: "4", name: "Servidores" },
];



const NuevoTicketModal = ({
  setContactModal,
  contactModal,
  close,
}: propsDestructured) => {
  const { control, handleSubmit } = useForm();


  const ContactoName = useAppSelector((state) => state.ticket.Contacto.name);
  const ClasificacionName = useAppSelector(
    (state) => state.ticket.Clasificacion.name
  );

  const { addUser } = useServerUsers()

  const dipatch = useAppDispatch();
  useEffect(() => {
    if (ContactoName === "Nuevo Contacto") {
      setContactModal(true);
    }
  }, [ContactoName]);
  const onSubmit: SubmitHandler<Record<string, string | number | boolean | string[]>> = (
    data
  ) => {
    const date = Object.assign(data, {
      createdById: 2,
      assignedTo: 3,
      status: 'open'
    })
    console.log(date);
    addUser(deleteUndefinedAttr(date), close);
  };

  const closeContactModal = () => {
    setContactModal(false);
    dipatch(saveContactoSelection({ id: "", name: "" }));
  };




  return (
    <main>
      <div>
        <h3 className="p-4 text-xl md:text-2xl">Nuevo Ticket</h3>
        <form
          className="flex flex-col gap-y-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <AsyncComboBox
            name="name"
            control={control}
            rules={{ required: "Campo requerido" }}
            label="Cliente"
            dataQuery={{ url: "/contact" }}
            normalizeData={{ id: 'id', name: "name" }}
          ></AsyncComboBox>
          <div className=" grid grid-cols-4 items-center justify-center"><div className=" col-span-4"><ContactAsyncCombobox
            name="contact_id"
            control={control}
            contacto={true}
            rules={{ required: "Campo requerido" }}
            label="Contacto"
            dataQuery={{ url: "/contact" }}
            normalizeData={{ id: 'id', name: "name" }}
          ></ContactAsyncCombobox></div>
            {/*<button className="h-10 flex items-center justify-center w-full" ><PlusCircleIcon className="h-10 self-center self"></PlusCircleIcon></button>*/}
            </div>
          <ComboBox
            name="classification"
            Conectividad={true}
            control={control}
            label="Clasificacion"
            data={clasificacion}
          ></ComboBox>

          {ClasificacionName === "Conectividad" ? (
            <AsyncComboBox
              name="Dispositivo"
              control={control}
              rules={{ required: "Campo requerido" }}
              label="Dispositivo"
              dataQuery={{ url: "/device" }}
              normalizeData={{ id: 'id', name: "contrato" }}
            ></AsyncComboBox>
          ) : null}

          <ComboBox
            name="priority"
            control={control}
            label="Prioridad"
            data={prioridad}
          ></ComboBox>

          <TextArea
            name="description"
            control={control}
            label="Descripcion"
          ></TextArea>

          <div className="relative rounded-lg self-center lg:self-end w-[100%] lg:w-[30%] h-[40px] items-center justify-center flex mt-8 bg-indigo-600  text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <Button type="submit" name="Insertar" color="blue-500"></Button>
          </div>
        </form>
      </div>
      {contactModal ? (
        <Modal state={contactModal} close={closeContactModal}>
          <CreateContactModal close={closeContactModal}></CreateContactModal>
        </Modal>
      ) : null}
    </main>
  );
};

export default NuevoTicketModal;
