export const data = [
    {
      no: "2",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "3",
      fecha: "17/10/10",
      email: "lbob@gmail.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "4",
      fecha: "20/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "4",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
  
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
  
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
  
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
    {
      no: "1",
      fecha: "17/10/10",
      email: "lindsay.walton@example.com",
      cliente: "LegolandStudio",
      clasificacion: "Industrial",
      prioridad: "alta",
      description: "lorem ipaskdjasljdnaosjdnaksjdnbalskjdbalskjdbalksjdbaskj",
    },
  ];
  